import { IconPlus, IconTimes } from '../../icon/icon'

export const ItemAdd = ({ onClick }) => {
  return (
    <div className="border rounded p-1 m-1 bg-primary text-white" onClick={onClick}>
      <IconPlus />
    </div>
  )
}

export const ItemTag = ({ item, truncate, fnTagFormat, onRemove }) => {
  const handleClick = () => onRemove && onRemove(item)

  return (
    <div className={`border rounded p-1 m-1 bg-primary text-white text-nowrap ${truncate && 'text-truncate'}`}>
      <span className="px-1">
        {fnTagFormat(item)}
      </span>
      <span className="px-1" onClick={handleClick}>
        <IconTimes />
      </span>
    </div>
  )
}

export const ItemTagReadOnly = ({ item, truncate, fnTagFormat }) => {
  return (
    <div className={`border rounded p-1 m-1 bg-secondary text-white text-nowrap ${truncate && 'text-truncate'}`}>
      <span className="px-1">
        {fnTagFormat(item)}
      </span>
    </div>
  )
}
