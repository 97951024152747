import React, { useMemo, useState } from 'react'
import { ItemAdd, ItemTag, ItemTagReadOnly } from './components/item-tag'
import { useModalActionID } from '../component/modal-dialog'
import { SelectList } from './components/select-list'
import { IconDown, IconUp } from '../icon/icon'

export const SelectTagNew = ({ selectedItems, readOnlyItems, listItems, fnTagFormat, fnListFormat, fnListFilter, onAdd, onRemove }) => {
  const [expand, updateExpand] = useState(false)

  const [modalTarget, modalShow, modalHide] = useModalActionID()

  const selectedItemArr = useMemo(() => selectedItems || [], [selectedItems])
  const readOnlyItemArr = useMemo(() => readOnlyItems || [], [readOnlyItems])

  const displaySelectedItems = useMemo(
    () => {
      let result = selectedItemArr.reduce((acc, item) => {
        if (readOnlyItemArr.includes(item)) {
          return acc
        } else {
          return [...acc, item]
        }
      }, [])

      return result
    },
    [selectedItemArr, readOnlyItemArr]
  )

  const displayListItems = useMemo(() => listItems || [], [listItems])

  const handleExpand = () => updateExpand(!expand)

  const handleSelectListClose = () => modalHide()

  const handleOpenSelectList = () => modalShow()

  return (
    <div className="input-group">
      <div className="form-control">
        <div className={`d-flex overflow-hidden ${expand && 'flex-wrap'}`}>
          <ItemAdd onClick={handleOpenSelectList} />

          {readOnlyItemArr.map((item, i) => <ItemTagReadOnly key={i} item={item} truncate={expand} fnTagFormat={fnTagFormat} />)}

          {displaySelectedItems.map((item, i) =>
            <ItemTag key={i} item={item} truncate={expand} fnTagFormat={fnTagFormat} onRemove={onRemove} />
          )}
        </div>

        <SelectList
          selectedItems={selectedItems}
          items={displayListItems}
          modalTarget={modalTarget}
          fnListFormat={fnListFormat}
          fnListFilter={fnListFilter}
          onAdd={onAdd}
          onRemove={onRemove}
          onClose={handleSelectListClose}
        />
      </div>
      <span className="input-group-text" onClick={handleExpand}>
        {expand ? <IconUp /> : <IconDown />}
      </span>
    </div>
  )
}
