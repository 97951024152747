import React, { useMemo, useState } from 'react'
import { IconDelete } from '../../../../../../shared/icon/icon'

export const Input = ({ children, label, showRequired, showError }) => {
  return (
    <div class="mb-2">
      <label for="input" class="form-label">
        {showRequired
          ? <span className="text-danger">
              {label} (required)
            </span>
          : <React.Fragment>
              {showError
                ? <span className="text-danger">
                    {label} ({showError})
                  </span>
                : <span>
                    {label}
                  </span>}
            </React.Fragment>}
      </label>
      <div>
        {children}
      </div>
    </div>
  )
}

export const InputEmail = ({ value, index, onChange }) => {
  const handleChange = e => onChange && onChange(index, e.target.value)

  const showRequired = useMemo(() => !value, [value])
  let emailValid = useMemo(() => new RegExp(process.env.REACT_APP_REGEX_EMAIL, 'g').test(value), [value])

  return (
    <Input label="email" showRequired={showRequired} showError={!emailValid && 'invalid email'}>
      <input type="text" class="form-control" value={value} placeholder="email" onChange={handleChange} />
    </Input>
  )
}

export const InputFirstName = ({ value, index, onChange }) => {
  const handleChange = e => onChange && onChange(index, e.target.value)

  const showRequired = useMemo(() => !value, [value])

  return (
    <Input label="first name" showRequired={showRequired}>
      <input type="text" class="form-control" value={value} placeholder="first name" onChange={handleChange} />
    </Input>
  )
}

export const InputLastName = ({ value, index, onChange }) => {
  const handleChange = e => onChange && onChange(index, e.target.value)

  const showRequired = useMemo(() => !value, [value])

  return (
    <Input label="last name" showRequired={showRequired}>
      <input type="text" class="form-control" value={value} placeholder="last name" onChange={handleChange} />
    </Input>
  )
}

export const InputRoles = ({ label, roles, index, onChange }) => {
  const handleAdd = value => {
    let newRoles = [...roles]
    newRoles.push(value)
    onChange && onChange(index, newRoles)
  }

  const handleDelete = value => {
    let newRoles = [...roles]
    let removeIndex = newRoles.findIndex(i => i === value)
    newRoles.splice(removeIndex, 1)
    onChange && onChange(index, newRoles)
  }

  return (
    <React.Fragment>
      <div class="form-floating mb-3">
        <SelectTag
          allItems={['item1', 'item2', 'item3', 'item4', 'item5']}
          selectedItems={roles}
          onAdd={handleAdd}
          onDelete={handleDelete}
        />
        <label for="floatingInput">
          <span>
            {label}
          </span>
        </label>
      </div>
    </React.Fragment>
  )
}

const SelectTag = ({ allItems, selectedItems, onAdd, onDelete }) => {
  const editableItems = useMemo(() => selectedItems || [], [selectedItems])

  const dropdownItems = useMemo(
    () => {
      let listItems = allItems || []

      editableItems.forEach(editableItem => {
        if (listItems.includes(editableItem)) {
          listItems = listItems.filter(i => i !== editableItem)
        }
      })

      return listItems
    },
    [allItems, editableItems]
  )

  const handleChange = e => {
    onAdd && onAdd(e.target.value)
    updateValue('')
  }

  const handleDelete = item => onDelete && onDelete(item)

  const [value, updateValue] = useState('')

  return (
    <React.Fragment>
      <div className="d-flex flex-wrap border form-control">
        {editableItems.map((item, i) =>
          <div key={i} className="pe-3">
            <span>
              {item}
            </span>
            <span onClick={() => handleDelete(item)}>
              <IconDelete />
            </span>
          </div>
        )}

        <select className="" value={value} onChange={handleChange}>
          <option value={''}>Add Role</option>
          {dropdownItems.map((item, i) =>
            <option key={i} value={item}>
              {item}
            </option>
          )}
        </select>
      </div>
    </React.Fragment>
  )
}
