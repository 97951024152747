import React, { useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { Input, InputEmail, InputFirstName, InputLastName } from './user-login-add-new/input'
import { SelectGroup } from './user-login-add-new/select-group'
import { SelectRoles } from './user-login-add-new/select-roles'
import { SelectNodeContent } from './user-login-add-new/select-node-content'
import { useApiListGroup, useApiListNodeDecendantContent, useApiListRoleOrg } from '../../../../../api-new/state/content'
import { useLoad } from '../../../../../shared/hooks/load'
import { ImportCSVNew } from './user-login-add-new/import-csv/import-csv'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'

export const UserLoginAddNew = () => {
  const [users, updateUsers] = useState([{}])

  const apiListRoleOrg = useApiListRoleOrg()
  const apiListGroup = useApiListGroup()
  const apiListNodeDecendantContent = useApiListNodeDecendantContent()

  useLoad(() => apiListRoleOrg.get(), [])
  useLoad(() => apiListGroup.get(), [])
  useLoad(() => apiListNodeDecendantContent.get(), [])

  const handleSelect = rows => {
    updateUsers(rows)
  }

  const handleAdd = () => {
    updateUsers([...users, {}])
  }

  const handleChange = (index, user) => {
    let newUsers = [...users]

    newUsers[index] = user

    updateUsers(newUsers)
  }

  const handleInviteAll = () => {
    updateUsers([{}])
  }

  const [commonRoles, updateCommonRoles] = useState([])
  const [commonGroups, updateCommonGroups] = useState([])
  const [commonContent, updateCommonContent] = useState([])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <CardFullHeight title="add users">
          <div className="d-flex flex-column h-100">
            <div className="border-bottom p-1">
              <ImportCSVNew
                roleItems={apiListRoleOrg.items}
                groupItems={apiListGroup.items}
                contentItems={apiListNodeDecendantContent.items}
                onSelect={handleSelect}
              />

              <button onClick={handleAdd}>Add</button>
            </div>

            <div className="flex-fill overflow-y-scroll overflow-x-hidden h-100">
              <UserList
                users={users}
                roleItems={apiListRoleOrg.items}
                groupItems={apiListGroup.items}
                contentItems={apiListNodeDecendantContent.items}
                commonRoles={commonRoles}
                commonGroups={commonGroups}
                commonContent={commonContent}
                onChange={handleChange}
              />
            </div>
            <div className="border-top pt-2">
              <div className="container1 1m-0">
                <div className="row">
                  <div className="col-12">
                    <div className="p-2">
                      <button onClick={handleInviteAll}>Invite All</button>
                    </div>
                  </div>
                  <div className="col-12 col-xl-4">
                    <Input label="roles">
                      <SelectRoles selectedRoles={commonRoles} allRoles={apiListRoleOrg.items} onUpdate={updateCommonRoles} />
                    </Input>
                  </div>
                  <div className="col-12 col-xl-4">
                    <Input label="groups">
                      <SelectGroup selectedGroups={commonGroups} allGroups={apiListGroup.items} onUpdate={updateCommonGroups} />
                    </Input>
                  </div>
                  <div className="col-12 col-xl-4">
                    <Input label="content">
                      <SelectNodeContent
                        selectedContent={commonContent}
                        allContent={apiListNodeDecendantContent.items}
                        onUpdate={updateCommonContent}
                      />
                    </Input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardFullHeight>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const UserList = ({ users, roleItems, groupItems, contentItems, commonRoles, commonGroups, commonContent, onChange }) => {
  const handleUserChange = (index, user) => {
    onChange && onChange(index, user)
  }

  return (
    <React.Fragment>
      {users.map((user, i) =>
        <User
          key={i}
          user={user}
          roleItems={roleItems}
          groupItems={groupItems}
          contentItems={contentItems}
          commonRoles={commonRoles}
          commonGroups={commonGroups}
          commonContent={commonContent}
          index={i}
          onChange={handleUserChange}
        />
      )}
    </React.Fragment>
  )
}

const User = ({ user, roleItems, groupItems, contentItems, commonRoles, commonGroups, commonContent, index, onChange }) => {
  const readOnlyRoles = useMemo(() => commonRoles || [], [commonRoles])
  const readOnlyGroups = useMemo(() => commonGroups || [], [commonGroups])

  const selectedRoles = useMemo(() => (user.roles ? [...user.roles] : []), [user])
  const selectedGroups = useMemo(() => (user.groups ? [...user.groups] : []), [user])
  const selectedContent = useMemo(() => (user.contents ? [...user.contents] : []), [user])

  const handleChangeEmail = (index, value) => onChange && onChange(index, { ...user, email: value })
  const handleChangeFirstName = (index, value) => onChange && onChange(index, { ...user, firstName: value })
  const handleChangeLastName = (index, value) => onChange && onChange(index, { ...user, lastName: value })

  const handleRoleUpdate = items => {
    let newUser = { ...user, roles: items }
    onChange && onChange(index, newUser)
  }

  const handleGroupUpdate = items => {
    let newUser = { ...user, groups: items }
    onChange && onChange(index, newUser)
  }

  const handleContentUpdate = items => {
    let newUser = { ...user, contents: items }
    onChange && onChange(index, newUser)
  }

  return (
    <React.Fragment>
      <Card>
        <div className="1bg-secondary">
          {/* <div className="container m-0 bg-info"> */}
          <div className="row 1bg-danger">
            <div className="col-12 6col-lg-6">
              <InputEmail value={user.email} index={index} onChange={handleChangeEmail} />
            </div>

            <div className="col-12 col-lg-6">
              <InputFirstName value={user.firstName} index={index} onChange={handleChangeFirstName} />
            </div>

            <div className="col-12 col-lg-6">
              <InputLastName value={user.lastName} index={index} onChange={handleChangeLastName} />
            </div>

            <div className="col-12 col-lg-6">
              <Input label="roles">
                <SelectRoles selectedRoles={selectedRoles} readOnlyRoles={readOnlyRoles} allRoles={roleItems} onUpdate={handleRoleUpdate} />
              </Input>
            </div>

            <div className="col-12 col-lg-6">
              <Input label="groups">
                <SelectGroup
                  selectedGroups={selectedGroups}
                  readOnlyGroups={readOnlyGroups}
                  allGroups={groupItems}
                  onUpdate={handleGroupUpdate}
                />
              </Input>
            </div>

            <div className="col-12 col-lg-6">
              <Input label="content">
                <SelectNodeContent
                  selectedContent={selectedContent}
                  readOnlyContent={commonContent}
                  allContent={contentItems}
                  onUpdate={handleContentUpdate}
                />
              </Input>
            </div>
          </div>
          {/* </div> */}
        </div>
      </Card>
    </React.Fragment>
  )
}
