import { useDisplaySysAdmin } from '../../../../../../role/role.component'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { IconCopy, IconFile, IconMove } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuAdd, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { useMetadataTypeIsCaffeine } from '../../common/hooks/hooks'

export const NodeTemplateMenuFull = (url, node) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  const isCaffeine = useMetadataTypeIsCaffeine(node)

  return (
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuItem text="test" show />
      <MenuAdd text="new course" to={`${url}/generate`} disabled={!isCaffeine} show={roleContent.accessCreate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show={true} />
    </Menu>
  )
}

export const NodeTemplateMenuPartial = (url, node) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} show={roleContent.accessCreate} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
    </Menu>
  )
}
