import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { NodeCourseEdit } from './node-course-edit'
import { NodeCourseDelete } from './node-course-delete'
import { Grid, GridItem3 } from '../../../../../../shared/chart/grid'
import { RoleAccessDisplayOrg } from '../../../../../../role/components/role-access-display'
import { CourseAssessment } from '../graphs/course-assessment'
import { AnalyticGeneralCourse } from '../graphs/analytic-general-course'
import { AnalyticCustomCourse } from '../graphs/analytic-custom-course'
import { CourseHeatmap } from '../graphs/course-heatmap'
import { IconCourse } from '../../../../../../shared/icon/icon'
import { NodeCourseMove } from './node-course-move'
import { NodeCourseCopy } from './node-course-copy'
import { AnalyticLayout } from '../../../../../../view/common/analytic/page/course/analytic-legacy/layout'
import { HeatmapLayoutNode } from '../../../../../../view/heatmap/layout-node'
import { AssessmentRoute } from '../../../../../../view/common/assessment/route'
import { AnalyticRoute } from '../../../../../../view/common/analytic/page/course/analytic-course-custom/route'
import { useDataContext } from '../../data-context'
import { NodeCourseEditSys } from './node-course-edit-sys'
import { LogEventOrgSubjectID } from '../../../../../../shared/page/log-event/log-event-org'
import { DisplaySysAdmin } from '../../../../../../role/role.component'
import { CourseDeepLink } from '../../common/model/course-deep-link'
import { NodeCourseTemplate } from './node-course-template'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { NodePermission } from '../../common/permission/node-permission'

export const NodeCourseDetails = ({ parentURL }) => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.nodes.find(i => i.id === nodeID) || {}, [dataContext.nodes, nodeID])

  const roleAssessmentManage = useRoleAccessOrganization(ROLE_ORG.ASSESSMENT_MANAGE)
  const roleAnalytic = useRoleAccessOrganization(ROLE_ORG.ANALYTIC)

  const HeaderMenu = () => NodeContentMenuFull(url, itemCurrent, true)

  return (
    <React.Fragment>
      <BreadcrumbView text={itemCurrent.name} Icon={IconCourse}>
        <CardFullHeightScrollY title={itemCurrent.name} HeaderMenu={HeaderMenu}>
          <Grid>
            <RoleAccessDisplayOrg type={ROLE_ORG.ASSESSMENT_REPORT} accessRead>
              <GridItem3>
                <CourseAssessment nodeID={nodeID} showConfigButton={roleAssessmentManage.accessRead} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.ANALYTIC} accessRead>
              <GridItem3>
                <AnalyticGeneralCourse nodeID={nodeID} showConfigButton={roleAnalytic.accessUpdate} />
              </GridItem3>
              <GridItem3>
                <AnalyticCustomCourse nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.HEATMAP} accessRead>
              <GridItem3>
                <CourseHeatmap nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
          </Grid>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      {itemCurrent &&
        <React.Fragment>
          <NodePermission url={url} nodeID={nodeID} />

          <Route path={`${url}/move`}>
            <NodeCourseMove parentURL={url} nodeID={nodeID} />
          </Route>
        </React.Fragment>}

      <Route path={`${url}/edit`}>
        <NodeCourseEdit parentURL={url} nodeID={nodeID} />
      </Route>

      <DisplaySysAdmin>
        <Route path={`${url}/edit-sys`}>
          <NodeCourseEditSys parentURL={url} nodeID={nodeID} />
        </Route>
      </DisplaySysAdmin>

      <Route path={`${url}/delete`}>
        <NodeCourseDelete grandParentURL={parentURL} parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/copy`}>
        <NodeCourseCopy nodeID={nodeID} parentURL={url} />
      </Route>

      <Route path={`${url}/template`}>
        <NodeCourseTemplate nodeID={nodeID} parentURL={url} />
      </Route>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={nodeID} />
      </Route>

      <AnalyticRoute />
      <AnalyticLayout />
      <HeatmapLayoutNode />
      <AssessmentRoute />

      <CourseDeepLink targetID="courseLink" nodeID={nodeID} />
    </React.Fragment>
  )
}
