export const SelectHeader = ({ headers, value, onChange }) => {
  const handleChange = e => {
    onChange && onChange(e.target.value)
  }

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value={-1}>select</option>
      {headers &&
        headers.map((header, i) =>
          <option key={i} value={i}>
            {header}
          </option>
        )}
    </select>
  )
}
