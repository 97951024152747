import React from 'react'
import { SelectTagNew } from '../../../../../../shared/select-tag-new/select-tag'

export const SelectRoles = ({ allRoles, selectedRoles, readOnlyRoles, onUpdate }) => {
  const handleAdd = item => {
    let newRoles = [...selectedRoles, item]
    onUpdate && onUpdate(newRoles)
  }

  const handleRemove = item => {
    let newRoles = selectedRoles.filter(i => i.id !== item.id)
    onUpdate && onUpdate(newRoles)
  }

  return (
    <React.Fragment>
      <SelectTagNew
        selectedItems={selectedRoles}
        readOnlyItems={readOnlyRoles}
        listItems={allRoles}
        fnTagFormat={item => item.role}
        fnListFormat={item => item.role}
        onAdd={handleAdd}
        onRemove={handleRemove}
        fnListFilter={(filter, items) => items.filter(item => item.role.toLowerCase().includes(filter))}
      />
    </React.Fragment>
  )
}
