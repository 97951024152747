import React, { useMemo } from 'react'
import {
  useApiFormNodeCourseCategory,
  useApiListNodeCourseCategory,
  useApiListNodeDecendantContent
} from '../../../../../api-new/state/content'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { NodeCourseDetails } from './pages/node-course-details'
import { IconCategory, IconContent } from '../../../../../shared/icon/icon'
import { NodeContentGridItem } from './node-content-grid-item'
import { NodeContentFilterList } from './node-content-filter-list'
import { useDataContext } from '../data-context'
import { FilterInput, useFilterList } from '../common/filter/filter-input'
import { isDatatypeCategory, isDatatypeCourse, isDatatypeStreamingVideo } from '../common/shared/metadata'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'
import { NodeCourseAdd } from './pages/node-course-add'
import { NodeCategory } from '../node-category/node-category'
import { pathNodeContentMap } from '../common/shared/path-node-content'
import { NodeCategoryGridItem } from '../node-category/node-category-grid-item'
import { NodeCategoryMenuFull } from '../node-category/common/node-category-menu'

export const NodeContentGrid = () => {
  return (
    <React.Fragment>
      <div className="container-fluid p-0 h-100">
        <NodeContentGridBranch />
      </div>
    </React.Fragment>
  )
}

const NodeContentGridBranch = ({ grandParentURL }) => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.content.find(i => i.id === parentID), [dataContext.content, parentID])
  const children = useMemo(() => dataContext.content.filter(i => i.parentID === (parentID || null)), [dataContext.content, parentID])
  const nodeCategories = useMemo(() => children.filter(item => isDatatypeCategory(item)), [children])
  const nodeCourses = useMemo(() => children.filter(item => isDatatypeCourse(item) || isDatatypeStreamingVideo(item)), [children])

  const title = useMemo(
    () => {
      if (parentID) return itemCurrent ? itemCurrent.name : ''
      else return 'CONTENT'
    },
    [parentID, itemCurrent]
  )

  const Icon = useMemo(
    () => {
      if (parentID) return IconCategory
      else return IconContent
    },
    [parentID]
  )

  const HeaderMenu = () => NodeCategoryMenuFull(url, parentID, !!parentID)

  const [filter, updateFilter, showFilter, selectFilter] = useFilterList()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={Icon} text={title}>
        <CardFullHeight title={title} HeaderMenu={HeaderMenu}>
          <div className="d-flex flex-column h-100">
            <div className="m-2">
              <FilterInput value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill" style={{ minHeight: 0 }}>
              <div className="overflow-scroll h-100">
                {showFilter
                  ? <div>
                      <NodeContentFilterList nodes={dataContext.content} filter={filter} onSelect={selectFilter} />
                    </div>
                  : <div className="container-fluid">
                      <div className="row g-2">
                        {nodeCategories
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeCategoryGridItem key={i} node={node} />)}
                        {nodeCourses
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeContentGridItem key={i} node={node} />)}
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </CardFullHeight>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <NodeContentGridBranch grandParentURL={url} />
      </Route>

      <Route path={`${url}/course/:nodeID`}>
        <NodeCourseDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add-type`}>
        <NodeCourseAdd parentURL={url} parentID={parentID} />
      </Route>

      <NodeCategory
        url={url}
        grandParentURL={grandParentURL}
        nodeID={parentID}
        useApiListCategory={useApiListNodeCourseCategory}
        useApiFormCategory={useApiFormNodeCourseCategory}
        useApiListRecycle={useApiListNodeDecendantContent}
        pathNodeFn={pathNodeContentMap}
        includePermissions
      />
    </React.Fragment>
  )
}
