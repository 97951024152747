import React from 'react'
import { NodePermissionUser } from './node-permission-user'
import { Route } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodePermissionUserAdd } from './node-permission-user-add'
import { NodePermissionGroup } from './node-permission-group'
import { NodePermissionGroupAdd } from './node-permission-group-add'

export const NodePermission = ({ url, nodeID }) => {
  return (
    <React.Fragment>
      <Route path={`${url}/user-permission`}>
        <NodePermissionUser nodeID={nodeID} />
      </Route>

      <Route path={`${url}/user-permission/add`}>
        <NodePermissionUserAdd nodeID={nodeID} />
      </Route>

      <Route path={`${url}/group-permission`}>
        <NodePermissionGroup nodeID={nodeID} />
      </Route>

      <Route path={`${url}/group-permission/add`}>
        <NodePermissionGroupAdd nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
