import React, { useEffect, useMemo, useRef, useState } from 'react'
import Papa from 'papaparse'
import { SelectFile } from './component/file-select'
import { ModalDialog, useModalActions } from '../../../../../../../shared/component/modal-dialog'
import { SelectHeader } from './component/select-header'
import { useLoad } from '../../../../../../../shared/hooks/load'

export const ImportCSVNew = ({ roleItems, groupItems, contentItems, onSelect }) => {
  const ref = useRef()

  const [headers, updateHeaders] = useState()
  const [rows, updateRows] = useState()

  let modelActions = useModalActions('import')

  const handleClick = () => {
    ref.current.click()
  }

  const handleFileSelect = files => {
    const reader = new FileReader()

    reader.onload = () => {
      const result = Papa.parse(reader.result, { header: false, delimiter: ',' })

      var headers = result.data[0].filter(i => i !== '')
      let rows = result.data.slice(1)

      updateHeaders(headers)
      updateRows(rows)

      modelActions.show()
    }

    reader.readAsText(files[0])
  }

  const handleSelect = (indexes, selectedRoles, selectedGroups, selectedContents) => {
    debugger

    let result = rows.map(row => {
      let roleValue = row[indexes.roleIndex]

      let roles = []

      if (roleValue !== '') {
        let roleNames = roleValue.toLowerCase().split(',')

        roleNames.forEach(roleName => {
          let selectedRole = selectedRoles.find(i => i.roleName === roleName)

          if (selectedRole) {
            let role = roleItems.find(role => role.id === selectedRole.roleID)
            if (role) {
              roles.push(role)
            }
          }
        })
      }

      let groupValue = row[indexes.groupIndex]

      let groups = []

      if (groupValue !== '') {
        let groupNames = groupValue.toLowerCase().split(',')

        groupNames.forEach(groupName => {
          let selectedGroup = selectedGroups.find(i => i.groupName === groupName)

          if (selectedGroup) {
            let group = groupItems.find(group => group.id === selectedGroup.groupID)
            if (group) {
              groups.push(group)
            }
          }
        })
      }

      let contentValue = row[indexes.contentIndex]

      let contents = []

      if (contentValue !== '') {
        let contentNames = contentValue.toLowerCase().split(',')

        contentNames.forEach(contentName => {
          let selectedContent = selectedContents.find(i => i.contentName === contentName)

          if (selectedContent) {
            let content = contentItems.find(content => content.id === selectedContent.contentID)
            if (content) {
              contents.push(content)
            }
          }
        })
      }

      let hold = { email: row[indexes.emailIndex], roles: roles, groups: groups, contents: contents }
      return hold
    })

    debugger

    modelActions.hide()

    onSelect && onSelect(result)
  }

  return (
    <React.Fragment>
      <button onClick={handleClick}>new import</button>
      <SelectFile ref={ref} onFileSelect={handleFileSelect} />
      <ImportModal
        headers={headers}
        rows={rows}
        roleItems={roleItems}
        groupItems={groupItems}
        contentItems={contentItems}
        onSelect={handleSelect}
      />
    </React.Fragment>
  )
}

const ImportModal = ({ headers, rows, roleItems, groupItems, contentItems, onSelect }) => {
  const [emailIndex, updateEmailIndex] = useState(-1)
  const [roleIndex, updateRoleIndex] = useState(-1)
  const [groupIndex, updateGroupIndex] = useState(-1)
  const [contentIndex, updateContentIndex] = useState(-1)

  const [roleNames, updateRoleNames] = useState([])
  const [groupNames, updateGroupNames] = useState([])
  const [contentNames, updateContentNames] = useState([])

  const [roleListValues, updateRoleListValues] = useState({})
  const [groupListValues, updateGroupListValues] = useState({})
  const [contentListValues, updateContentListValues] = useState({})

  const handleRoleChange = (index, roleID, roleName) => {
    let newRoleListValues = { ...roleListValues }

    if (!newRoleListValues[index]) {
      newRoleListValues[index] = {}
    }

    newRoleListValues[index].roleID = roleID
    newRoleListValues[index].roleName = roleName

    updateRoleListValues(newRoleListValues)
  }

  const handleGroupChange = (index, groupID, groupName) => {
    let newGroupListValues = { ...groupListValues }

    if (!newGroupListValues[index]) {
      newGroupListValues[index] = {}
    }

    newGroupListValues[index].groupID = groupID
    newGroupListValues[index].groupName = groupName

    updateGroupListValues(newGroupListValues)
  }

  const handleContentChange = (index, contentID, contentName) => {
    let newContentListValues = { ...contentListValues }

    if (!newContentListValues[index]) {
      newContentListValues[index] = {}
    }

    newContentListValues[index].contentID = contentID
    newContentListValues[index].contentName = contentName

    updateContentListValues(newContentListValues)
  }

  useEffect(
    () => {
      if (headers) {
        updateEmailIndex(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'email'))
        // updateFirstname(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'firstname'))
        // updateLastname(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'lastname'))
        updateRoleIndex(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'roles'))
        updateGroupIndex(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'groups'))
        updateContentIndex(headers.findIndex(i => i.replace(' ', '').toLowerCase() === 'content'))
      }
    },
    [headers]
  )

  useEffect(
    () => {
      if (roleIndex !== -1) {
        let roles = getUniqueValues(rows, roleIndex)
        updateRoleNames(roles)
      }
    },
    [rows, roleIndex]
  )

  useEffect(
    () => {
      if (groupIndex !== -1) {
        let groups = getUniqueValues(rows, groupIndex)
        updateGroupNames(groups)
      }
    },
    [rows, groupIndex]
  )

  useEffect(
    () => {
      if (contentIndex !== -1) {
        let content = getUniqueValues(rows, contentIndex)
        updateContentNames(content)
      }
    },
    [rows, contentIndex]
  )

  const handleSelect = () => {
    let indexes = { emailIndex: emailIndex, roleIndex: roleIndex, groupIndex: groupIndex, contentIndex: contentIndex }

    let selectedRoles = Object.values(roleListValues)
    let selectedGroups = Object.values(groupListValues)
    let selectedContents = Object.values(contentListValues)

    onSelect && onSelect(indexes, selectedRoles, selectedGroups, selectedContents)
  }

  return (
    <ModalDialog target="import">
      <div>
        <div>
          Email: <SelectHeader headers={headers} value={emailIndex} onChange={updateEmailIndex} />{' '}
        </div>
        <div>
          Roles: <SelectHeader headers={headers} value={roleIndex} onChange={updateRoleIndex} />{' '}
        </div>
        <div>
          Groups: <SelectHeader headers={headers} value={groupIndex} onChange={updateGroupIndex} />{' '}
        </div>
        <div>
          Content: <SelectHeader headers={headers} value={contentIndex} onChange={updateContentIndex} />{' '}
        </div>

        <div />

        <div>
          {roleNames.map((roleName, i) =>
            <div key={i}>
              ROLE: {roleName}
              <SelectRole index={i} roleItems={roleItems} roleName={roleName} onChange={handleRoleChange} />
            </div>
          )}
        </div>

        <div>
          {groupNames.map((groupName, i) =>
            <div key={i}>
              GROUP: {groupName}
              <SelectGroup index={i} groupItems={groupItems} groupName={groupName} onChange={handleGroupChange} />
            </div>
          )}
        </div>

        <div>
          {contentNames.map((contentName, i) =>
            <div key={i}>
              CONTENT: {contentName}
              <SelectContent index={i} contentItems={contentItems} contentName={contentName} onChange={handleContentChange} />
            </div>
          )}
        </div>

        <div>
          <button onClick={handleSelect}>CONTINUE</button>
        </div>
      </div>
    </ModalDialog>
  )
}

const SelectRole = ({ index, roleItems, roleName, onChange }) => {
  const [value, updateValue] = useState(-1)

  const handleChange = e => updateValue(e.target.value)

  useLoad(() => onChange && onChange(index, parseInt(value), roleName), [value])

  const displayRoles = useMemo(
    () => {
      if (!roleItems) {
        return []
      }
      return roleItems.filter(role => role.role.toLowerCase().includes(roleName))
    },
    [roleItems, roleName]
  )

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value={-1}>select</option>

      {displayRoles.map((role, i) =>
        <option key={i} value={role.id}>
          {role.role}
        </option>
      )}
    </select>
  )
}

const SelectGroup = ({ index, groupItems, groupName, onChange }) => {
  const [value, updateValue] = useState(-1)

  const handleChange = e => updateValue(e.target.value)

  useLoad(() => onChange && onChange(index, parseInt(value), groupName), [value])

  const displayGroups = useMemo(
    () => {
      if (!groupItems) {
        return []
      }
      return groupItems.filter(group => group.name.toLowerCase().includes(groupName))
    },
    [groupItems, groupName]
  )

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value={-1}>select</option>

      {displayGroups.map((group, i) =>
        <option key={i} value={group.id}>
          {group.name}
        </option>
      )}
    </select>
  )
}

const SelectContent = ({ index, contentItems, contentName, onChange }) => {
  const [value, updateValue] = useState(-1)

  const handleChange = e => updateValue(e.target.value)

  useLoad(() => onChange && onChange(index, parseInt(value), contentName), [value])

  const displayGroups = useMemo(
    () => {
      if (!contentItems) {
        return []
      }
      return contentItems.filter(content => content.name.toLowerCase().includes(contentName))
    },
    [contentItems, contentName]
  )

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value={-1}>select</option>

      {displayGroups.map((group, i) =>
        <option key={i} value={group.id}>
          {group.name}
        </option>
      )}
    </select>
  )
}

const getUniqueValues = (arr, index) => {
  let items = arr.reduce((acc, row) => {
    let item = row[index]
    if (item) {
      return [...acc, ...item.toLowerCase().split(',')]
    }
    return acc
  }, [])

  return [...new Set([...items])]
}
