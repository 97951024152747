import React, { useMemo, useState } from 'react'
import { ModalDialog } from '../../component/modal-dialog'
import { IconPlus } from '../../icon/icon'
import { CardFullHeight } from '../../card/card-full-height'
import { SelectListFilter } from './select-list-filter'
import { SelectListItem } from './select-list-item'

export const SelectList = ({ selectedItems, items, modalTarget, fnListFilter, fnListFormat, onAdd, onRemove, onClose }) => {
  const [filter, updateFilter] = useState('')

  const filterItems = useMemo(
    () => {
      if (filter) {
        return fnListFilter(filter, items)
      }
      return items
    },
    [items, filter, fnListFilter]
  )

  return (
    <React.Fragment>
      <ModalDialog target={modalTarget} header="HEADER" Icon={IconPlus} fullScreen>
        <CardFullHeight>
          <div className="d-flex flex-column h-100">
            <div>
              <SelectListFilter value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill overflow-y-scroll p-2">
              {filterItems.map((item, i) =>
                <SelectListItem
                  selectedItems={selectedItems}
                  key={i}
                  item={item}
                  fnListFormat={fnListFormat}
                  onAdd={onAdd}
                  onRemove={onRemove}
                />
              )}
            </div>
            <div>
              <button onClick={onClose}>CLOSE</button>
            </div>
          </div>
        </CardFullHeight>
      </ModalDialog>
    </React.Fragment>
  )
}
