import { useMemo } from 'react'
import { IconMinus, IconPlus } from '../../icon/icon'

export const SelectListItem = ({ selectedItems, item, fnListFormat, onAdd, onRemove }) => {
  const isSelected = useMemo(() => !!selectedItems.find(selectedItem => selectedItem.id === item.id), [item, selectedItems])

  const handleClick = () => {
    if (isSelected) onRemove(item)
    else onAdd(item)
  }

  return (
    <div className="d-flex" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <div className="border rounded p-1 m-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </div>
      <div className="border-bottom p-1 m-1 flex-fill">
        {fnListFormat(item)}
      </div>
    </div>
  )
}
